<template>
  <div class="scheduler-table">
    <b-card
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="7"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                placeholder="Поиск"
              />
            </b-input-group>
          </b-col>
          <!-- Search -->
          <b-col
            cols="5"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="$store.getters['user/permission']('schedule:delete')"
                variant="primary"
                :disabled="isProcessing"
                :to="{ name: 'scheduler-add' }"
              >
                <span class="text-nowrap">
                  Добавить
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <BOverlay
        :show="isProcessing"
        opacity="0.8"
        variant="transparent"
      >
        <b-table
          ref="usersTable"
          class="position-relative min-height"
          hover
          :items="schedulers"
          responsive
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          empty-text="Ничего не найдено"
          @row-clicked="onRowClicked"
        >
          <template #cell(facility_name)="{ item: scheduler }">
            <div class="d-flex align-items-center">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="scheduler.facility_avatar"
                    :text="avatarText(scheduler.facility.name)"
                    variant="light-primary"
                  />
                </template>
              </b-media>
              <div>
                <div
                  v-if="scheduler.facility.name"
                  class="font-weight-bolder"
                >
                  {{ scheduler.facility.name.length > 25
                    ? `${scheduler.facility.name.substring(0, 25)}...`
                    : scheduler.facility.name }}
                </div>
                <p class="mb-0">
                  {{ scheduler.facility_address }}
                </p>
              </div>
            </div>
          </template>

          <template #cell(work_type)="{ item: scheduler }">
            <div>
              {{ scheduler.work_type_name }}
            </div>
          </template>
          <template #cell(repeat)="{ item: scheduler }">
            <div v-if="scheduler.repeat === 'week' && scheduler.repeat_days">
              <span v-if="!!scheduler.repeat_days.find(item => item === 1)">Пн</span>
              <span v-if="!!scheduler.repeat_days.find(item => item === 2)">  ВТ</span>
              <span v-if="!!scheduler.repeat_days.find(item => item === 3)">  Ср</span>
              <span v-if="!!scheduler.repeat_days.find(item => item === 4)">  Чт</span>
              <span v-if="!!scheduler.repeat_days.find(item => item === 5)">  Пт</span>
              <span v-if="!!scheduler.repeat_days.find(item => item === 6)">  Сб</span>
              <span v-if="!!scheduler.repeat_days.find(item => item === 7)">  Вс</span>
            </div>
            <div
              v-else
              class="d-flex"
            >
              {{ scheduler.repeat_every }}
              {{ scheduler.repeat === 'year'
                ? 'год' : scheduler.repeat === 'month'
                  ? 'мес.' : 'день' }}
            </div>
          </template>
          <template #cell(start_date)="{ item: scheduler }">
            <div>
              {{ scheduler.start_date | readableDate }}
            </div>
          </template>
          <template #cell(end_date)="{ item: scheduler }">
            <div>
              {{ scheduler.end_date | readableDate }}
            </div>
          </template>
          <template #cell(active)="{ item: scheduler }">
            <div>
              <b-modal
                :id="`scheduler-delete-modal-${scheduler.id}`"
                cancel-variant="outline-secondary"
                ok-variant="danger"
                centered
                title="Удаление плановой заявки"
                size="sm"
                ok-title="Удалить"
                cancel-title="Отмена"
                @ok="schedulerDelete(scheduler.id)"
              >
                <h5>Вы действительно хотите удалить эту плановою заявку?</h5>
              </b-modal>
              <b-dropdown
                v-if="$store.getters['user/permission']('schedule:delete')"
                variant="white"
                toggle-class="text-decoration-none relative"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    class="text-body mx-auto font-weight-bolder"
                    icon="MoreVerticalIcon"
                  />
                </template>
                <b-dropdown-item
                    v-b-modal="`scheduler-delete-modal-${scheduler.id}`"
                    class="border-0 w-100"
                    @click.prevent
                >
                  <feather-icon
                      class="text-danger font-weight-bolder mr-1"
                      icon="Trash2Icon"
                  />
                  <span class="text-danger">
                      {{ t('settings.delete') }}
                    </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </BOverlay>
      <div
        v-if="schedulers.length"
        class="mx-2 mb-2"
      >
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center
              justify-content-sm-start
              text-muted
            "
          >
            <span class="mr-1">Показывать по</span>
            <b-form-group style="width: 90px; margin: 0">
              <AppSelect
                v-model="pagination.perPage"
                style="width: 100%"
                :searchable="false"
                label="title"
                :clearable="false"
                :options="perPageOptions"
              />
            </b-form-group>
            <span class="ml-1">
              {{ 'из ' + totalScheduler }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalPages > 1"
              v-model="pagination.page"
              :total-rows="totalScheduler"
              :per-page="pagination.perPage"
              first-number
              last-number
              :limit="isMobile ? 1 : 5"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol, BFormGroup,
  BFormInput, BInputGroup,
  BInputGroupPrepend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BAvatar,
  BMedia,
  BDropdown,
  BDropdownItem,
  BModal,
  // BBadge,
} from 'bootstrap-vue';
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { useRouter } from '@/hooks/useRouter';
import { getSchedulers, deleteScheduler } from '@/store/scheduler/scheduler.api';
import { useStore } from '@/hooks/useStore';
import useCache from '@/hooks/useCache';
import {useI18n} from "@/hooks/useI18n";

export default {
  name: 'SchedulerIndexPage',
  components: {
    BCard,
    BAvatar,
    BMedia,
    BRow,
    BCol,
    // BBadge,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BOverlay,
    AppSelect,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  props: {
    noCache: Boolean,
  },
  setup(props) {
    const { router } = useRouter();
    const { t } = useI18n();

    const usersTable = ref(null);

    const store = useStore();
    const isMobile = ref(false);

    isMobile.value = store.state.app.isMobile;

    const tableColumns = [
      { key: 'facility_name', label: 'Объект', sortable: true },
      { key: 'work_type', label: 'Вид работ', sortable: true },
      { key: 'repeat', label: 'Повтор', sortable: true },
      { key: 'start_date', label: 'Начало', sortable: true },
      { key: 'end_date', label: 'Конец', sortable: true },
      { key: 'active', label: '', sortable: false },
    ];

    const schedulers = ref([]);
    const totalScheduler = ref(0);

    const cache = useCache();
    const { noCache } = toRefs(props);
    const cacheParams = ref({
      prefix: 'schedulerTable',
      storage: !noCache.value,
      url: !noCache.value,
    });
    const paginationPage = cache.create({
      defaultValue: '1',
      name: 'page',
      ...cacheParams.value,
      storage: false,
    });
    const paginationPerPage = cache.create({
      defaultValue: '25',
      name: 'perPage',
      ...cacheParams.value,
    });
    const pagination = ref({
      page: paginationPage,
      perPage: paginationPerPage,
    });

    const totalPages = computed(() => Math.ceil(totalScheduler.value / pagination.value.perPage));
    const perPageOptions = ['10', '25', '50', '100'];

    const searchQuery = cache.create({
      defaultValue: '',
      name: 'query',
      ...cacheParams.value,
      // storage: false,
    });
    const sortBy = cache.create({
      defaultValue: 'id',
      name: 'sortBy',
      ...cacheParams.value,
    });
    const isSortDirDesc = cache.create({
      defaultValue: false,
      name: 'isSortDirDesc',
      ...cacheParams.value,
      boolean: true,
    });

    const isProcessing = ref(false);
    const getSchedulerData = () => {
      isProcessing.value = true;
      getSchedulers({
        q: searchQuery.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        ...pagination.value,
      }).then((res) => {
        schedulers.value = res.data.response.data;
        // pagination.value.perPage = res.data.response.per_page;
        totalScheduler.value = res.data.response.total;
      }).finally(() => {
        isProcessing.value = false;
      });
    };

    const isNeedToUpdateSortNavigation = ref(false);
    let sortNavigationDebounceTimer = null;
    watch([sortBy, isSortDirDesc], () => {
      clearTimeout(sortNavigationDebounceTimer);
      if (sortBy.value) {
        sortNavigationDebounceTimer = setTimeout(() => {
          isNeedToUpdateSortNavigation.value = true;
          sortNavigationDebounceTimer = null;
        }, 5);
      }
    });
    watch(isNeedToUpdateSortNavigation, (value) => {
      if (value) {
        getSchedulerData();
        isNeedToUpdateSortNavigation.value = false;
      }
    });
    const page = computed(() => pagination.value.page);
    const perPage = computed(() => pagination.value.perPage);
    watch(page, () => {
      getSchedulerData();
    }, { deep: true });
    watch(perPage, () => {
      pagination.value.page = 1;
      getSchedulerData();
    });

    watch(searchQuery, () => {
      getSchedulerData();
    });
    getSchedulerData();

    const onRowClicked = (scheduler) => {
      router.push({ name: 'scheduler-view', params: { id: scheduler.id } });
    };

    const schedulerDelete = (schedulerId) => {
      deleteScheduler(schedulerId).then(() => {
        const index = schedulers.value.findIndex((item) => item.id === schedulerId);
        schedulers.value.splice(index, 1);
      });
    };

    if (!store.state.checklists.length)store.dispatch('getChecklists');
    if (!store.state.equipments.length)store.dispatch('getEquipments');
    if (!store.state.managers.length)store.dispatch('getManagers');
    if (!store.state.facilities.length)store.dispatch('getFacilities');
    if (!store.state.workTypes.length)store.dispatch('getWorkTypes');
    if (!store.state.employees.length)store.dispatch('getEmployees');

    return {
      usersTable,
      tableColumns,
      schedulerDelete,
      t,

      schedulers,
      totalScheduler,

      pagination,
      totalPages,
      perPageOptions,
      isMobile,

      searchQuery,
      sortBy,
      isSortDirDesc,

      isProcessing,
      getSchedulerData,

      onRowClicked,

      avatarText,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.scheduler-table {
  display: flex;
  flex-direction: column;
  > .card {
    flex: 1
  }
}
</style>
